/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://jw27zpdda9.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "fdcAPI",
            "endpoint": "https://m3gw7glod3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "insights",
            "endpoint": "https://a3yxltjhyf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ukzh7fsnvzbura72q3zgp7nghq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ipwosjzp5rahnbhmego3suumay",
    "aws_cognito_identity_pool_id": "us-east-1:5c8fcf06-58c8-4a54-a598-5b67ec7fbb52",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_914dvmKPC",
    "aws_user_pools_web_client_id": "3l12k00ou16gvujlj9b2m43mgq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dashboardcd4637d9c794470d8f3e1d579147d0f3174656-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
