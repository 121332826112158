/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEducation = /* GraphQL */ `
  query GetEducation($id: ID!) {
    getEducation(id: $id) {
      id
      title
      type
      emoji
      ref
      tags
      isPublic
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEducations = /* GraphQL */ `
  query ListEducations(
    $filter: ModelEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        type
        emoji
        ref
        tags
        isPublic
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      app
      name
      needsConsent
      isPublic
      consentForm {
        includeDate
        page
        ref
        __typename
      }
      demographics {
        id
        options
        question
        type
        __typename
      }
      education
      medications
      symptoms
      emails {
        name
        email
        __typename
      }
      fullDescription
      shortDescription
      surveys
      externalSurveys {
        title
        ref
        __typename
      }
      notifications {
        title
        body
        hour
        __typename
      }
      institution
      usernameFormat
      numUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        app
        name
        needsConsent
        isPublic
        education
        medications
        symptoms
        fullDescription
        shortDescription
        surveys
        institution
        usernameFormat
        numUsers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWellnessLog = /* GraphQL */ `
  query GetWellnessLog($id: ID!) {
    getWellnessLog(id: $id) {
      id
      appUserID
      dateToLog
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      status
      feeling
      sleep
      symptoms
      deviceModel
      deviceOSVersion
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWellnessLogs = /* GraphQL */ `
  query ListWellnessLogs(
    $filter: ModelWellnessLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWellnessLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        dateToLog
        dateAndTimeToLog
        dateAndTimeStarted
        dateAndTimeSubmitted
        status
        feeling
        sleep
        symptoms
        deviceModel
        deviceOSVersion
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMedicationLog = /* GraphQL */ `
  query GetMedicationLog($id: ID!) {
    getMedicationLog(id: $id) {
      id
      appUserID
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      medications
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMedicationLogs = /* GraphQL */ `
  query ListMedicationLogs(
    $filter: ModelMedicationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicationLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        dateAndTimeToLog
        dateAndTimeStarted
        dateAndTimeSubmitted
        medications
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEducationView = /* GraphQL */ `
  query GetEducationView($id: ID!) {
    getEducationView(id: $id) {
      id
      educationID
      appUserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEducationViews = /* GraphQL */ `
  query ListEducationViews(
    $filter: ModelEducationViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEducationViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        educationID
        appUserID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHeartRate = /* GraphQL */ `
  query GetHeartRate($id: ID!) {
    getHeartRate(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listHeartRates = /* GraphQL */ `
  query ListHeartRates(
    $filter: ModelHeartRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeartRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRestingHeartRate = /* GraphQL */ `
  query GetRestingHeartRate($id: ID!) {
    getRestingHeartRate(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRestingHeartRates = /* GraphQL */ `
  query ListRestingHeartRates(
    $filter: ModelRestingHeartRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRestingHeartRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHeartRateVariabilitySDNN = /* GraphQL */ `
  query GetHeartRateVariabilitySDNN($id: ID!) {
    getHeartRateVariabilitySDNN(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listHeartRateVariabilitySDNNS = /* GraphQL */ `
  query ListHeartRateVariabilitySDNNS(
    $filter: ModelHeartRateVariabilitySDNNFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHeartRateVariabilitySDNNS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWalkingHeartRateAverage = /* GraphQL */ `
  query GetWalkingHeartRateAverage($id: ID!) {
    getWalkingHeartRateAverage(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWalkingHeartRateAverages = /* GraphQL */ `
  query ListWalkingHeartRateAverages(
    $filter: ModelWalkingHeartRateAverageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWalkingHeartRateAverages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStepCount = /* GraphQL */ `
  query GetStepCount($id: ID!) {
    getStepCount(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStepCounts = /* GraphQL */ `
  query ListStepCounts(
    $filter: ModelStepCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStepCounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOxygenSaturation = /* GraphQL */ `
  query GetOxygenSaturation($id: ID!) {
    getOxygenSaturation(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOxygenSaturations = /* GraphQL */ `
  query ListOxygenSaturations(
    $filter: ModelOxygenSaturationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOxygenSaturations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRespiratoryRate = /* GraphQL */ `
  query GetRespiratoryRate($id: ID!) {
    getRespiratoryRate(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRespiratoryRates = /* GraphQL */ `
  query ListRespiratoryRates(
    $filter: ModelRespiratoryRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespiratoryRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBloodPressureDiastolic = /* GraphQL */ `
  query GetBloodPressureDiastolic($id: ID!) {
    getBloodPressureDiastolic(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBloodPressureDiastolics = /* GraphQL */ `
  query ListBloodPressureDiastolics(
    $filter: ModelBloodPressureDiastolicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBloodPressureDiastolics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSleepSession = /* GraphQL */ `
  query GetSleepSession($id: ID!) {
    getSleepSession(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      minutesAsleep
      timeInBed
      efficiency
      infoCode
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const listSleepSessions = /* GraphQL */ `
  query ListSleepSessions(
    $filter: ModelSleepSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSleepSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        minutesAsleep
        timeInBed
        efficiency
        infoCode
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSleepLevel = /* GraphQL */ `
  query GetSleepLevel($id: ID!) {
    getSleepLevel(id: $id) {
      id
      appUserID
      sleepSessionID
      deviceProductType
      deviceOSVersion
      startDate
      level
      seconds
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const listSleepLevels = /* GraphQL */ `
  query ListSleepLevels(
    $filter: ModelSleepLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSleepLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appUserID
        sleepSessionID
        deviceProductType
        deviceOSVersion
        startDate
        level
        seconds
        createdAt
        owner
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBloodPressureSystolic = /* GraphQL */ `
  query GetBloodPressureSystolic($id: ID!) {
    getBloodPressureSystolic(id: $id) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBloodPressureSystolics = /* GraphQL */ `
  query ListBloodPressureSystolics(
    $filter: ModelBloodPressureSystolicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBloodPressureSystolics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appUserID
        deviceProductType
        deviceOSVersion
        startDate
        endDate
        sourceID
        eventID
        value
        unit
        sampleContext
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashUser = /* GraphQL */ `
  query GetDashUser($id: ID!) {
    getDashUser(id: $id) {
      id
      firstName
      lastName
      institution
      position
      roles {
        role
        groupID
        __typename
      }
      type
      email
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDashUsers = /* GraphQL */ `
  query ListDashUsers(
    $filter: ModelDashUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        institution
        position
        type
        email
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAppUser = /* GraphQL */ `
  query GetAppUser($id: ID!) {
    getAppUser(id: $id) {
      id
      demographics
      groups
      usernames
      streak
      badges
      tasks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppUsers = /* GraphQL */ `
  query ListAppUsers(
    $filter: ModelAppUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        demographics
        groups
        usernames
        streak
        badges
        tasks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeal = /* GraphQL */ `
  query GetMeal($id: ID!) {
    getMeal(id: $id) {
      id
      dateAndTimeSubmitted
      mealTime
      appUserID
      name
      foods {
        name
        portion
        portionID
        count
        fdcid
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMeals = /* GraphQL */ `
  query ListMeals(
    $filter: ModelMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateAndTimeSubmitted
        mealTime
        appUserID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mealsByAppUserIDAndMealTime = /* GraphQL */ `
  query MealsByAppUserIDAndMealTime(
    $appUserID: String!
    $mealTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mealsByAppUserIDAndMealTime(
      appUserID: $appUserID
      mealTime: $mealTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateAndTimeSubmitted
        mealTime
        appUserID
        name
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      conversationID
      userID
      message
      from
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        conversationID
        userID
        message
        from
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExerciseSession = /* GraphQL */ `
  query GetExerciseSession($id: ID!) {
    getExerciseSession(id: $id) {
      id
      dateAndTimeSubmitted
      dateAndTimeToLog
      appUserID
      duration
      durationUnit
      exercises {
        id
        exerciseType
        exerciseName
        resistance
        numSets
        repsPerSet
        exertion
        distance
        distanceUnit
        duration
        durationUnit
        usedAssistanceDevice
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listExerciseSessions = /* GraphQL */ `
  query ListExerciseSessions(
    $filter: ModelExerciseSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExerciseSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateAndTimeSubmitted
        dateAndTimeToLog
        appUserID
        duration
        durationUnit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
