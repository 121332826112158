/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWellnessLog = /* GraphQL */ `
  mutation CreateWellnessLog(
    $input: CreateWellnessLogInput!
    $condition: ModelWellnessLogConditionInput
  ) {
    createWellnessLog(input: $input, condition: $condition) {
      id
      appUserID
      dateToLog
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      status
      feeling
      sleep
      symptoms
      deviceModel
      deviceOSVersion
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMedicationLog = /* GraphQL */ `
  mutation CreateMedicationLog(
    $input: CreateMedicationLogInput!
    $condition: ModelMedicationLogConditionInput
  ) {
    createMedicationLog(input: $input, condition: $condition) {
      id
      appUserID
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      medications
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEducationView = /* GraphQL */ `
  mutation CreateEducationView(
    $input: CreateEducationViewInput!
    $condition: ModelEducationViewConditionInput
  ) {
    createEducationView(input: $input, condition: $condition) {
      id
      educationID
      appUserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHeartRate = /* GraphQL */ `
  mutation CreateHeartRate(
    $input: CreateHeartRateInput!
    $condition: ModelHeartRateConditionInput
  ) {
    createHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRestingHeartRate = /* GraphQL */ `
  mutation CreateRestingHeartRate(
    $input: CreateRestingHeartRateInput!
    $condition: ModelRestingHeartRateConditionInput
  ) {
    createRestingHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createHeartRateVariabilitySDNN = /* GraphQL */ `
  mutation CreateHeartRateVariabilitySDNN(
    $input: CreateHeartRateVariabilitySDNNInput!
    $condition: ModelHeartRateVariabilitySDNNConditionInput
  ) {
    createHeartRateVariabilitySDNN(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createWalkingHeartRateAverage = /* GraphQL */ `
  mutation CreateWalkingHeartRateAverage(
    $input: CreateWalkingHeartRateAverageInput!
    $condition: ModelWalkingHeartRateAverageConditionInput
  ) {
    createWalkingHeartRateAverage(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createStepCount = /* GraphQL */ `
  mutation CreateStepCount(
    $input: CreateStepCountInput!
    $condition: ModelStepCountConditionInput
  ) {
    createStepCount(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createOxygenSaturation = /* GraphQL */ `
  mutation CreateOxygenSaturation(
    $input: CreateOxygenSaturationInput!
    $condition: ModelOxygenSaturationConditionInput
  ) {
    createOxygenSaturation(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRespiratoryRate = /* GraphQL */ `
  mutation CreateRespiratoryRate(
    $input: CreateRespiratoryRateInput!
    $condition: ModelRespiratoryRateConditionInput
  ) {
    createRespiratoryRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createBloodPressureDiastolic = /* GraphQL */ `
  mutation CreateBloodPressureDiastolic(
    $input: CreateBloodPressureDiastolicInput!
    $condition: ModelBloodPressureDiastolicConditionInput
  ) {
    createBloodPressureDiastolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSleepSession = /* GraphQL */ `
  mutation CreateSleepSession(
    $input: CreateSleepSessionInput!
    $condition: ModelSleepSessionConditionInput
  ) {
    createSleepSession(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      minutesAsleep
      timeInBed
      efficiency
      infoCode
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const createSleepLevel = /* GraphQL */ `
  mutation CreateSleepLevel(
    $input: CreateSleepLevelInput!
    $condition: ModelSleepLevelConditionInput
  ) {
    createSleepLevel(input: $input, condition: $condition) {
      id
      appUserID
      sleepSessionID
      deviceProductType
      deviceOSVersion
      startDate
      level
      seconds
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const createBloodPressureSystolic = /* GraphQL */ `
  mutation CreateBloodPressureSystolic(
    $input: CreateBloodPressureSystolicInput!
    $condition: ModelBloodPressureSystolicConditionInput
  ) {
    createBloodPressureSystolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAppUser = /* GraphQL */ `
  mutation CreateAppUser(
    $input: CreateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    createAppUser(input: $input, condition: $condition) {
      id
      demographics
      groups
      usernames
      streak
      badges
      tasks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMeal = /* GraphQL */ `
  mutation CreateMeal(
    $input: CreateMealInput!
    $condition: ModelMealConditionInput
  ) {
    createMeal(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      mealTime
      appUserID
      name
      foods {
        name
        portion
        portionID
        count
        fdcid
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      conversationID
      userID
      message
      from
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExerciseSession = /* GraphQL */ `
  mutation CreateExerciseSession(
    $input: CreateExerciseSessionInput!
    $condition: ModelExerciseSessionConditionInput
  ) {
    createExerciseSession(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      dateAndTimeToLog
      appUserID
      duration
      durationUnit
      exercises {
        id
        exerciseType
        exerciseName
        resistance
        numSets
        repsPerSet
        exertion
        distance
        distanceUnit
        duration
        durationUnit
        usedAssistanceDevice
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEducation = /* GraphQL */ `
  mutation CreateEducation(
    $input: CreateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    createEducation(input: $input, condition: $condition) {
      id
      title
      type
      emoji
      ref
      tags
      isPublic
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEducation = /* GraphQL */ `
  mutation UpdateEducation(
    $input: UpdateEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    updateEducation(input: $input, condition: $condition) {
      id
      title
      type
      emoji
      ref
      tags
      isPublic
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEducation = /* GraphQL */ `
  mutation DeleteEducation(
    $input: DeleteEducationInput!
    $condition: ModelEducationConditionInput
  ) {
    deleteEducation(input: $input, condition: $condition) {
      id
      title
      type
      emoji
      ref
      tags
      isPublic
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      app
      name
      needsConsent
      isPublic
      consentForm {
        includeDate
        page
        ref
        __typename
      }
      demographics {
        id
        options
        question
        type
        __typename
      }
      education
      medications
      symptoms
      emails {
        name
        email
        __typename
      }
      fullDescription
      shortDescription
      surveys
      externalSurveys {
        title
        ref
        __typename
      }
      notifications {
        title
        body
        hour
        __typename
      }
      institution
      usernameFormat
      numUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      app
      name
      needsConsent
      isPublic
      consentForm {
        includeDate
        page
        ref
        __typename
      }
      demographics {
        id
        options
        question
        type
        __typename
      }
      education
      medications
      symptoms
      emails {
        name
        email
        __typename
      }
      fullDescription
      shortDescription
      surveys
      externalSurveys {
        title
        ref
        __typename
      }
      notifications {
        title
        body
        hour
        __typename
      }
      institution
      usernameFormat
      numUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      app
      name
      needsConsent
      isPublic
      consentForm {
        includeDate
        page
        ref
        __typename
      }
      demographics {
        id
        options
        question
        type
        __typename
      }
      education
      medications
      symptoms
      emails {
        name
        email
        __typename
      }
      fullDescription
      shortDescription
      surveys
      externalSurveys {
        title
        ref
        __typename
      }
      notifications {
        title
        body
        hour
        __typename
      }
      institution
      usernameFormat
      numUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWellnessLog = /* GraphQL */ `
  mutation UpdateWellnessLog(
    $input: UpdateWellnessLogInput!
    $condition: ModelWellnessLogConditionInput
  ) {
    updateWellnessLog(input: $input, condition: $condition) {
      id
      appUserID
      dateToLog
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      status
      feeling
      sleep
      symptoms
      deviceModel
      deviceOSVersion
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWellnessLog = /* GraphQL */ `
  mutation DeleteWellnessLog(
    $input: DeleteWellnessLogInput!
    $condition: ModelWellnessLogConditionInput
  ) {
    deleteWellnessLog(input: $input, condition: $condition) {
      id
      appUserID
      dateToLog
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      status
      feeling
      sleep
      symptoms
      deviceModel
      deviceOSVersion
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMedicationLog = /* GraphQL */ `
  mutation UpdateMedicationLog(
    $input: UpdateMedicationLogInput!
    $condition: ModelMedicationLogConditionInput
  ) {
    updateMedicationLog(input: $input, condition: $condition) {
      id
      appUserID
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      medications
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMedicationLog = /* GraphQL */ `
  mutation DeleteMedicationLog(
    $input: DeleteMedicationLogInput!
    $condition: ModelMedicationLogConditionInput
  ) {
    deleteMedicationLog(input: $input, condition: $condition) {
      id
      appUserID
      dateAndTimeToLog
      dateAndTimeStarted
      dateAndTimeSubmitted
      medications
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEducationView = /* GraphQL */ `
  mutation UpdateEducationView(
    $input: UpdateEducationViewInput!
    $condition: ModelEducationViewConditionInput
  ) {
    updateEducationView(input: $input, condition: $condition) {
      id
      educationID
      appUserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEducationView = /* GraphQL */ `
  mutation DeleteEducationView(
    $input: DeleteEducationViewInput!
    $condition: ModelEducationViewConditionInput
  ) {
    deleteEducationView(input: $input, condition: $condition) {
      id
      educationID
      appUserID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateHeartRate = /* GraphQL */ `
  mutation UpdateHeartRate(
    $input: UpdateHeartRateInput!
    $condition: ModelHeartRateConditionInput
  ) {
    updateHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteHeartRate = /* GraphQL */ `
  mutation DeleteHeartRate(
    $input: DeleteHeartRateInput!
    $condition: ModelHeartRateConditionInput
  ) {
    deleteHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRestingHeartRate = /* GraphQL */ `
  mutation UpdateRestingHeartRate(
    $input: UpdateRestingHeartRateInput!
    $condition: ModelRestingHeartRateConditionInput
  ) {
    updateRestingHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRestingHeartRate = /* GraphQL */ `
  mutation DeleteRestingHeartRate(
    $input: DeleteRestingHeartRateInput!
    $condition: ModelRestingHeartRateConditionInput
  ) {
    deleteRestingHeartRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateHeartRateVariabilitySDNN = /* GraphQL */ `
  mutation UpdateHeartRateVariabilitySDNN(
    $input: UpdateHeartRateVariabilitySDNNInput!
    $condition: ModelHeartRateVariabilitySDNNConditionInput
  ) {
    updateHeartRateVariabilitySDNN(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteHeartRateVariabilitySDNN = /* GraphQL */ `
  mutation DeleteHeartRateVariabilitySDNN(
    $input: DeleteHeartRateVariabilitySDNNInput!
    $condition: ModelHeartRateVariabilitySDNNConditionInput
  ) {
    deleteHeartRateVariabilitySDNN(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateWalkingHeartRateAverage = /* GraphQL */ `
  mutation UpdateWalkingHeartRateAverage(
    $input: UpdateWalkingHeartRateAverageInput!
    $condition: ModelWalkingHeartRateAverageConditionInput
  ) {
    updateWalkingHeartRateAverage(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteWalkingHeartRateAverage = /* GraphQL */ `
  mutation DeleteWalkingHeartRateAverage(
    $input: DeleteWalkingHeartRateAverageInput!
    $condition: ModelWalkingHeartRateAverageConditionInput
  ) {
    deleteWalkingHeartRateAverage(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateStepCount = /* GraphQL */ `
  mutation UpdateStepCount(
    $input: UpdateStepCountInput!
    $condition: ModelStepCountConditionInput
  ) {
    updateStepCount(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteStepCount = /* GraphQL */ `
  mutation DeleteStepCount(
    $input: DeleteStepCountInput!
    $condition: ModelStepCountConditionInput
  ) {
    deleteStepCount(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOxygenSaturation = /* GraphQL */ `
  mutation UpdateOxygenSaturation(
    $input: UpdateOxygenSaturationInput!
    $condition: ModelOxygenSaturationConditionInput
  ) {
    updateOxygenSaturation(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOxygenSaturation = /* GraphQL */ `
  mutation DeleteOxygenSaturation(
    $input: DeleteOxygenSaturationInput!
    $condition: ModelOxygenSaturationConditionInput
  ) {
    deleteOxygenSaturation(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRespiratoryRate = /* GraphQL */ `
  mutation UpdateRespiratoryRate(
    $input: UpdateRespiratoryRateInput!
    $condition: ModelRespiratoryRateConditionInput
  ) {
    updateRespiratoryRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRespiratoryRate = /* GraphQL */ `
  mutation DeleteRespiratoryRate(
    $input: DeleteRespiratoryRateInput!
    $condition: ModelRespiratoryRateConditionInput
  ) {
    deleteRespiratoryRate(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateBloodPressureDiastolic = /* GraphQL */ `
  mutation UpdateBloodPressureDiastolic(
    $input: UpdateBloodPressureDiastolicInput!
    $condition: ModelBloodPressureDiastolicConditionInput
  ) {
    updateBloodPressureDiastolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBloodPressureDiastolic = /* GraphQL */ `
  mutation DeleteBloodPressureDiastolic(
    $input: DeleteBloodPressureDiastolicInput!
    $condition: ModelBloodPressureDiastolicConditionInput
  ) {
    deleteBloodPressureDiastolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSleepSession = /* GraphQL */ `
  mutation UpdateSleepSession(
    $input: UpdateSleepSessionInput!
    $condition: ModelSleepSessionConditionInput
  ) {
    updateSleepSession(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      minutesAsleep
      timeInBed
      efficiency
      infoCode
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const deleteSleepSession = /* GraphQL */ `
  mutation DeleteSleepSession(
    $input: DeleteSleepSessionInput!
    $condition: ModelSleepSessionConditionInput
  ) {
    deleteSleepSession(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      minutesAsleep
      timeInBed
      efficiency
      infoCode
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateSleepLevel = /* GraphQL */ `
  mutation UpdateSleepLevel(
    $input: UpdateSleepLevelInput!
    $condition: ModelSleepLevelConditionInput
  ) {
    updateSleepLevel(input: $input, condition: $condition) {
      id
      appUserID
      sleepSessionID
      deviceProductType
      deviceOSVersion
      startDate
      level
      seconds
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const deleteSleepLevel = /* GraphQL */ `
  mutation DeleteSleepLevel(
    $input: DeleteSleepLevelInput!
    $condition: ModelSleepLevelConditionInput
  ) {
    deleteSleepLevel(input: $input, condition: $condition) {
      id
      appUserID
      sleepSessionID
      deviceProductType
      deviceOSVersion
      startDate
      level
      seconds
      createdAt
      owner
      updatedAt
      __typename
    }
  }
`;
export const updateBloodPressureSystolic = /* GraphQL */ `
  mutation UpdateBloodPressureSystolic(
    $input: UpdateBloodPressureSystolicInput!
    $condition: ModelBloodPressureSystolicConditionInput
  ) {
    updateBloodPressureSystolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteBloodPressureSystolic = /* GraphQL */ `
  mutation DeleteBloodPressureSystolic(
    $input: DeleteBloodPressureSystolicInput!
    $condition: ModelBloodPressureSystolicConditionInput
  ) {
    deleteBloodPressureSystolic(input: $input, condition: $condition) {
      id
      appUserID
      deviceProductType
      deviceOSVersion
      startDate
      endDate
      sourceID
      eventID
      value
      unit
      sampleContext
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDashUser = /* GraphQL */ `
  mutation CreateDashUser(
    $input: CreateDashUserInput!
    $condition: ModelDashUserConditionInput
  ) {
    createDashUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      institution
      position
      roles {
        role
        groupID
        __typename
      }
      type
      email
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDashUser = /* GraphQL */ `
  mutation UpdateDashUser(
    $input: UpdateDashUserInput!
    $condition: ModelDashUserConditionInput
  ) {
    updateDashUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      institution
      position
      roles {
        role
        groupID
        __typename
      }
      type
      email
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDashUser = /* GraphQL */ `
  mutation DeleteDashUser(
    $input: DeleteDashUserInput!
    $condition: ModelDashUserConditionInput
  ) {
    deleteDashUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      institution
      position
      roles {
        role
        groupID
        __typename
      }
      type
      email
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppUser = /* GraphQL */ `
  mutation UpdateAppUser(
    $input: UpdateAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    updateAppUser(input: $input, condition: $condition) {
      id
      demographics
      groups
      usernames
      streak
      badges
      tasks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppUser = /* GraphQL */ `
  mutation DeleteAppUser(
    $input: DeleteAppUserInput!
    $condition: ModelAppUserConditionInput
  ) {
    deleteAppUser(input: $input, condition: $condition) {
      id
      demographics
      groups
      usernames
      streak
      badges
      tasks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMeal = /* GraphQL */ `
  mutation UpdateMeal(
    $input: UpdateMealInput!
    $condition: ModelMealConditionInput
  ) {
    updateMeal(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      mealTime
      appUserID
      name
      foods {
        name
        portion
        portionID
        count
        fdcid
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMeal = /* GraphQL */ `
  mutation DeleteMeal(
    $input: DeleteMealInput!
    $condition: ModelMealConditionInput
  ) {
    deleteMeal(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      mealTime
      appUserID
      name
      foods {
        name
        portion
        portionID
        count
        fdcid
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      conversationID
      userID
      message
      from
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      conversationID
      userID
      message
      from
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExerciseSession = /* GraphQL */ `
  mutation UpdateExerciseSession(
    $input: UpdateExerciseSessionInput!
    $condition: ModelExerciseSessionConditionInput
  ) {
    updateExerciseSession(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      dateAndTimeToLog
      appUserID
      duration
      durationUnit
      exercises {
        id
        exerciseType
        exerciseName
        resistance
        numSets
        repsPerSet
        exertion
        distance
        distanceUnit
        duration
        durationUnit
        usedAssistanceDevice
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteExerciseSession = /* GraphQL */ `
  mutation DeleteExerciseSession(
    $input: DeleteExerciseSessionInput!
    $condition: ModelExerciseSessionConditionInput
  ) {
    deleteExerciseSession(input: $input, condition: $condition) {
      id
      dateAndTimeSubmitted
      dateAndTimeToLog
      appUserID
      duration
      durationUnit
      exercises {
        id
        exerciseType
        exerciseName
        resistance
        numSets
        repsPerSet
        exertion
        distance
        distanceUnit
        duration
        durationUnit
        usedAssistanceDevice
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
